import React from "react";
import style from "./blogs.module.css";
import blog from "../../assets/blogs.png";
import photo from "../../assets/Photo.png";
import sign from "../../assets/buttonSign.png";
import sign2 from "../../assets/sign2.png";
import photo2 from "../../assets/Photo2.png";
import Slash from "../Shared/slash/Slash";

const Blogs = () => {
  return (
    <div className={style.container}>
      <div className={style.blog_first}>
        <img src={blog} alt="" srcset="" />
        <div className={style.content}>
          <span>
            <Slash rang="#5956E8" />
            08 . Blogs
          </span>
          <h2 className={style.Benefits_heading}>
            Browse our content on growth marketing
          </h2>
        </div>
      </div>
      <div className={style.card1}>
        <div className={style.content2}>
          <div className={style.header}>
            <span className={style.category}>Marketing</span>
            <span className={`${style.date} ${style.visbleDate}`}>
              SEPTEMBER 1, 2022
            </span>
          </div>
          <h2 className={style.title}>
            How to increase your product sale by over 20% with this simple
            methods
          </h2>
          <p className={style.description}>
            Tincidunt donec vulputate ipsum erat urna auctor. Eget phasellus
            ideirs.adipiscing elit. Tincidunt donec vulputate ipsum erat urna
            auctor.
          </p>
          <button className={style.button}>
            Read More <img src={sign} alt="" srcset="" />
          </button>
        </div>
        <div className={style.imageContainer}>
          <span className={`${style.date} ${style.visbleDate1}`}>
            SEPTEMBER 1, 2022
          </span>
          <img src={photo} alt="Office meeting" className={style.image} />
        </div>
      </div>
      <div className={style.card2}>
        <div className={style.imageContainer}>
          <span className={`${style.category2} ${style.visiblebutton2}`}>
            Condent
          </span>
          <img src={photo2} alt="Office meeting" className={style.image2} />
        </div>
        <div className={style.content2}>
          <div className={style.header}>
            <span className={`${style.category2} ${style.visiblebutton1}`}>
              Condent
            </span>
            <span className={style.date}>SEPTEMBER 1, 2022</span>
          </div>
          <h2 className={style.title}>
            How to reach out for guest posts to increase your SEO authority
          </h2>
          <p className={style.description}>
            Tincidunt donec vulputate ipsum erat urna auctor. Eget phasellus
            ideirs.adipiscing elit. Tincidunt donec vulputate ipsum erat urna
            auctor.
          </p>
          <button className={`${style.button}`}>
            Read More <img src={sign} alt="" srcset="" />
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className={style.container2}>
        <button className={style.button2}>
          More Blogs <img src={sign2} alt="" srcset="" />
        </button>
      </div>
    </div>
  );
};

export default Blogs;
