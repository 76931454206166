import React from 'react'
import style from "./home.module.css"
import boy from "../../assets/Man.png";
import boy2 from "../../assets/mobileMan.png";
import Services from "../../homecomponents/Service/Services";
import About from "../../homecomponents/About/About";
import Benefits from "../../homecomponents/Benefits/Benefits";
import Projects from "../../homecomponents/Projects/Projects";
import Blogs from "../../homecomponents/Blogs/Blogs";
import Process from "../../homecomponents/Process/Process";
import Testimonial from "../../homecomponents/Testimonial/Testimonial";
import Choose from "../../homecomponents/Choose/choose";
import metrics from "../../assets/Metricitem.png";
import Contact from "../../homecomponents/Contact/Contact"
import Navbar from '../../homecomponents/Navbar/Navbar';
const Home = () => {
  return (<>
    <div className={style.back}>
    <Navbar />
    <div className={style.landing}>
      <div className={style.inner}>
        <span>
          <font
            style={{
              color: "#FEC90C",
              width: "85px",
              height: "23px",
              gap: "0px",
              opacity: "0px",
            }}
          >
            //
          </font>
          Agency
        </span>
        <h2>
          A fully integrated digital{" "}
          <font style={{ color: "#5956E8" }}>marketing and branding</font>{" "}
          agency
        </h2>
        <p>
          We provide Digital and Branding solutions for Product based
          companies.
        </p>
        <img src={boy2} />
        <div className={style.buts}>
          <button
            type="button"
            style={{
              background: "#5956E8",
            }}
            class="btn btn-primary"
          >
            Our Service
          </button>{" "}
          <button type="button" class="btn btn-outline-primary">
            Get Started
          </button>
        </div>
      </div>
      <div className={style.imgs}>
        {" "}
        <img src={boy} />
      </div>
    </div>
  </div>
  <About />
  <Process/>
  <Services />
  <Benefits/>
  <Projects/>
  <Choose/>
  <Testimonial/>
  <Blogs/>
  <Contact/>
  </>
  )
}

export default Home
