import React from 'react'
import style from "./FooterCard.module.css"
import cardlogo from "../../../assets/Footer_img.png"
import logo from "../../../assets/FooterCard.png"

const FooterCard = () => {
  return (
    <div className={style.container}>
      <div className={style.logo}>
        <img src={logo} alt="" srcset="" />
        <span>Grow Now</span>
      </div>
      <img src={cardlogo} alt="logo" />
      <div className={style.cardContent}>
        <span>Subscribe now</span>
        <p>Industry's standard from dummy and make a type book.</p>
      </div>
      <input color='#5B5B61' name="" id="emails" placeholder='Enter Your Email...'/>
      <button className={style.cardbtn}>Subscribe</button>
    </div>
  )
}

export default FooterCard
