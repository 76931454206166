import React from "react";
import Navbar from "../../homecomponents/Navbar/Navbar";

const Aboutpage = () => {
  return <>
  
  <Navbar/>

  </>;
};

export default Aboutpage;
