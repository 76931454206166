import React from "react";
import style from "./Benefits.module.css";
import blog from "./../../assets/Benefits.png";
import base from "../../assets/base.png";
import Vector1 from "../../assets/Vector1.png";
import Vector2 from "../../assets/Vector2.png";
import Vector3 from "../../assets/Vector3.png";
import Slash from "../Shared/slash/Slash";

const ben = [
  {
    imgs: Vector1,
    head: "Sale Improve",
    paragraph:
      "We help to improve your sales with ground level expertise to provide offline marketing solution.",
  },
  {
    imgs: Vector2,
    head: "Optimization of Business",
    paragraph:
      "Optimization of your business to make stand out to against you competitor.",
  },
  {
    imgs: Vector3,
    head: "Reporting & Analysis",
    paragraph:
      "Provide weekly, Monthly and Yearly business plan and Results to make transparency.",
  },
];

const Benefits = () => {
  return (
    <div className={style.Benefits}>
      <div className={style.contain}>
        <div className={style.blog_first}>
          <img src={blog} alt="" srcset="" />
          <div className={style.content}>
            <span>
            <Slash rang = "#5956E8"/>
              07 . Benefits
            </span>
            <h2 className={style.Benefits_heading}>
              Benefit of working with Brand Mitra
            </h2>
          </div>
        </div>
        <div className={style.right}>
          <img src={base} alt="Base" />
        </div>
      </div>
      <div className={style.results}>
        {ben.map((element, key) => (
          <div key={key} className={style.lines}>
            <span className={style.icon}>
              <img src={element.imgs} alt="" />
            </span>
            <div className={style.innerline}>
              <span className={style.head}>{element.head}</span>
              <p>{element.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
