import React from "react";
import style from "../Counts/counts.module.css";

const Counts = () => {
  return (
    <div className={style.container}>
      <div className={style.block}>
        <span className={style.number}>325<span style={{color:"#5956E8"}}>+</span></span>
        <span className={style.content}>CLIENTS
        WORLDWIDE</span>
      </div>
      <div className={style.block}>
        <span className={style.number}>975<span style={{color:"#FF1850"}}>+</span></span>
        <span className={style.content}>PROJECT COMPLETED</span>
      </div>
      <div className={style.block}>
        <span className={style.number}>70<span style={{color:"#FFDC60"}}>+</span></span>
        <span className={style.content}>TEAM   MEMBERS</span>
      </div>
      <div className={style.block}>
        <span className={style.number}>85m<span style={{color:"#38E55E"}}>+</span></span>
        <span className={style.content}>REVENUE GENERATED</span>
      </div>
    </div>
  );
};

export default Counts;
