import React from "react";
import style from "../contact.module.css";
import email from "../../../assets/email.png"
const Card = (props) => {
  return (
    <>
      <div className={style.cardcontainer}>
        <div className={style.inner}>
          <div className={style.header}>
            <span>{props.topic}</span>
          </div>
          <div className={style.bodies}>
            <span>
              {" "}
              <img src={props.icons} alt="" srcset="" />
            </span>
            <div className={style.contents}>
              <h2>{props.name}</h2>
              <p>nextlevel@marketing.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
