import React from "react";
import style from "./choose.module.css";
import tick from "../../assets/tick.png";
import first from "../../assets/third.png";
// import eclipse from "../../assets/eclipse.png"
import combine from "../../assets/combination.png";
import sign from "../../assets/buttonSign.png"

const ticks = [
  "Product Marketing",
  "Digital Marketing",
  "Development Solution",
  "Designing Solution",
];

function choose() {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.text_content}>
          <h3>WHY CHOOSE US</h3>
        <h1>Elevate your brand with our Innovative strategies.</h1>
          <ul>
            {ticks.map((element, key) => (
              <li key={key}>
                <img src={tick} alt="Tick Mark" />
                <span>{element}</span>
              </li>
            ))}
          </ul>
          <button className={style.know_more_button}>To Know More <img src={sign}/></button>
        </div>
        <div className={style.image_content}>
          <img src={combine} alt="" />
        </div>
        <button className={style.know_more_button1}>To Know More <img src={sign}/></button>
      </div>
    </div>
  );
}

export default choose;
