import React from "react";

const Slash = (props) => {
  return (
    <>
      {" "}
      <font
        style={{
          color: `${props.rang}`,
        }}
      >
        //
      </font>{" "}
    </>
  );
};

export default Slash;
