import React from "react";
import style from "./Service.module.css";
import design from "../../assets/design.png";
import digital from "../../assets/Digital.png";
import distribution from "../../assets/Distribution.png";
import ground from "../../assets/Ground.png";
import product from "../../assets/Product.png";
import setting from "../../assets/Settings.png";
import website from "../../assets/Website.png";
import Devcards from "./Devcard/Devcard";
import sign from "../../assets/buttonSign.png";
import Slash from "../Shared/slash/Slash";
import paid from "../../assets/paid.png";
const cardinfo = [
  {
    logo: digital,
    heading: "Digital Marketing",
    about: "SEO, SEM, SMM and other mar key={index}keting technique for the product ",
  },
  {
    logo: paid,
    heading: "Paid Advertising",
    about:
      "We help to make Product  demand and growth through targeted, strategic promotion",
  },
  {
    logo: design,
    heading: "Design Solution",
    about:
      "Provide Packaging, Brand Identity other Design solution for your Product  ",
  },
];
const cardinfo2 = [
  {
    logo: website,
    heading: "Website Development",
    about: "Website Designing and Development spicily focus on Product needs.",
  },
  {
    logo: distribution,
    heading: "Video Creation",
    about:
      "We also help in maintain distribution and capture new distribution network",
  },
  {
    logo: ground,
    heading: "Content Creation",
    about:
      "We are only company who provide ground level marketing solution for your Product. ",
  },
];

const Services = () => {
  return (
    <>
      <div className={style.back}>
        <div className={style.inner}>
          <img className={style.logo} src={setting} alt="" />

          <div className={style.heading}>
            <span>
              <Slash rang="#FEC90C" />
              01 . Services
            </span>
            <h2>
              High-impact services to take your business to the next level
            </h2>
          </div>
          <button className="btn btn-light">
            Our Services <img src={sign} alt="" srcset="" />
          </button>
        </div>
        <div className={style.develop}>
          <div className={`container ${style.develop__container}`}>
            <div className={style.develop__options}>
              <div className={style.rowss}>
                {cardinfo.map((element, index) => (
                  // <Devcards
                  //   key={index}
                  //   imgs={element.logo}
                  //   head={element.heading}
                  //   about={element.about}
                  // />
                  <>
                    <div  key={index} className={style.develop__option}>
                      <span className={style.spanicon}>
                        <img src={element.logo} alt="icon" />
                      </span>
                      <h5>{element.heading}</h5>
                      <p>{element.about}</p>
                      <button className={style.buttonbtn}>
                        Know More <img src={sign} alt="" srcset="" />
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div className={style.rowss}>
                {cardinfo2.map((element, index) => (
                  <Devcards
                    key={index}
                    imgs={element.logo}
                    head={element.heading}
                    about={element.about}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
