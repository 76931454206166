import React from "react";
import style from "./contact.module.css";
import icon from "../../assets/contact.png";
import Slash from "../Shared/slash/Slash";
import Card from "./card/Card";
import email from "../../assets/email.png";
import phone from "../../assets/phone.png";
import location from "../../assets/location.png";
import Contactform from "./contactForm/Contactform";

const Contact = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.contact_first}>
          <img src={icon} alt="" srcset="" />
          <div className={style.content}>
            <span>
              <Slash rang="#5956E8" />
              01 . CONTACT US
            </span>
            <h2>Let's build something awesome together</h2>
          </div>
        </div>
        <div className={style.details}>
          <Card topic="Contact" name="Email" icons={email} />
          <Card topic="Call" name="Phone" icons={phone} />
          <Card topic="office" name="location" icons={location} />
        </div>
        <Contactform />
      </div>
    </>
  );
};

export default Contact;
