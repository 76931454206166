import React from "react";
import style from "./process.module.css";
import icon from "../../assets/ProcessIcon.png";
import Slash from "../Shared/slash/Slash";
import process1 from "../../assets/Process1.png";
import process2 from "../../assets/Graphics(Process).png";
import process3 from "../../assets/Growth(process).png";

const Process = () => {
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <img src={icon} alt="" />
        <span>
          <Slash rang="#5956E8" />
          03 . Proccess
        </span>
        <h2>A simple, yet powerful and efficient process</h2>
      </div>
      <div className={style.contains}>
        <div className={style.content}>
          <img src={process1} alt="process image" />
          <div className={style.number}>1</div>
          <div className={style.material}>
            <h3>Marketing Plan</h3>
            <p>
              Make marketing and strategy planing and according to that move
              forward
            </p>
          </div>
        </div>
        <div className={style.content}>
          <div style={{textAlign:"right"}}className={style.material}>
            <h3>Work Execution</h3>
            <p>
              Divide all the plan to individual teams according to there domains
            </p>
          </div>
          <div className={style.number1}>2</div>
          <img style={{marginRight: "3.8rem"}} src={process2} alt="process image" />
        </div>
        <div className={style.content}>
          <img className={style.procImg} src={process3} alt="process image" />
          <div className={style.number2}>3</div>
          <div className={style.material}>
            <h3>Growth & Scale</h3>
            <p>With skills and experience we scale the Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
