import React from 'react'
import style from "./card.module.css"
import star from "../../../assets/Star.png"


const Card = (props) => {
  return (
    <div className={style.card}>
      <div className={style.innerCard}>
        <div className={style.heading}>
            <div className={style.name}><span><img src={""} alt=""/>{props.compName}</span> <img src={star} alt="" /></div>
            <p>{props.feedback}</p>
        </div>
      </div>
      <div className={style.about}>
        <img src={props.clientPhoto} alt="" />
        <div className={style.introduce}>
            <span className={style.client}>{props.name}</span>
            <span className={style.designation}>{props.clietDeg}</span>
        </div>
      </div>
    </div>
  )
}

export default Card
