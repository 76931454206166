import React from "react";
import style from "./testimonial.module.css";
import Avatar from "../../assets/Avtar1(testimonial).png";
import Avatar2 from "../../assets/Avtar2.png";
import Card from "./Card/Card";
import Slash from "../Shared/slash/Slash";
import icon from "../../assets/TestimonialIcon.png";
import left from "../../assets/Left.png";
import right from "../../assets/right.png";

const client = [
  {
    // companyLogo:
    Companyname: "Company",
    feedback:
      "Lorem Ipsum has been the industry's standard from dummy text ever since the unknown printer to galley of type and make a type specimen book.",
    clientPhoto: Avatar,
    name: "John Carter",
    clietDeg: "Marketing Lead at Google",
  },
  {
    // companyLogo:
    Companyname: "Company",
    feedback:
      "Lorem Ipsum has been the industry's standard from dummy text ever since the unknown printer to galley of type and make a type specimen book.",
    clientPhoto: Avatar2,
    name: "Peeter pawl",
    clietDeg: "Designer",
  },
];

const Testimonial = () => {
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <div className={style.title}>
          <div className={style.icon}>
            <img src={icon} alt="" />
          </div>
          <div className={style.topics}>
            <span>
              <Slash rang="#FEC90C" />
              04 . Testimonial
            </span>
            <h2>We work with customers across all industries</h2>
          </div>
        </div>
        <div className={style.signs}>
          {" "}
          <img src={left} /> <img src={right} />
        </div>
      </div>
      <div className={style.content}>
        {client.map((element, key) => (
          <Card
            compName={element.Companyname}
            feedback={element.feedback}
            client={element.name}
            clientPhoto={element.clientPhoto}
            name={element.name}
            clietDeg={element.clietDeg}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
