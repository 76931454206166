import React from "react";
import style from "./about.module.css";
import Man from "../../assets/Man.png";
import design from "../../assets/aboutimg.png";
import icon from "../../assets/Icon.png";
import icon2 from "../../assets/Icon2.png";
import Slash from "../Shared/slash/Slash";
import iconic from "../../assets/@icon.png";
const About = () => {
  return (
    <div className={style.about_section}>
      <div className={style.about_image}>
        <img src={design} alt="About" />
      </div>
      <div className={style.about_content}>
        <div className={style.inner}>
          <div className={style.heading}>
            <span>
              <Slash rang="#FEC90C" />
              02 . About Us
            </span>
            <h2 className={style.about_heading}>
              The #1 Digital marketing services company
            </h2>{" "}
          </div>
        </div>
        <p className={style.temp}>
          Empowering brands with innovative strategies and ground-level
          marketing solutions for impact-full growth.
        </p>
        <div className={style.sectiontwo}>
          <span className={style.icon}>
            <img src={icon} alt="" />
          </span>
          <div className={style.innerline}>
            <span>Guaranteed Results</span>
            <p>
              We provide highly guaranteed result and make sure achieve
              millstones of business
            </p>
          </div>
        </div>
        <div className={style.sectiontwo}>
          {" "}
          <span className={style.icon}>
            <img src={icon2} alt="" />
          </span>
          <div className={style.innerline}>
            <span>Team of Industry Experts</span>
            <p>We are experts and experience Team in there specific domain.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
