import React from "react";
import Logo from "../../assets/Logo.png";
import "./navbar.css";
import LogoMobile from "../../assets/mobilelogo.png";
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid align logo">
          <Link

            className="navbar-brand"
            to="/"
          >
            <img src={Logo} />
          </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="blueline navbar-nav me-auto mb-2 mb-lg-0 ">
                <li className="nav-item " data-animation="to-left">
                  <Link className={`nav-link blue uline`} to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue`} to="/about">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue `} to="/blogs">
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue`} to="/services">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue `} to="/career">
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue`} to="/contact">
                    Contact Us
                  </Link>
                </li>
                <button
                  type="button"
                  style={{
                    width: "182px",
                    height: "66px",
                    padding: "17px 39px 18px 39px",
                    borderRadius: "7px",
                    background: "#5956E8",
                  }}
                  class="btn btn-primary"
                >
                  Get Started
                </button>
              </ul>
            </div>
          </div>
      </nav>
    </>
  );
}

export default Navbar;
