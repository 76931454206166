import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home/Home"
import Footer from "./homecomponents/Footer/Footer";
import About from "./pages/AboutPage/Aboutpage";
function App() {
  return (
    <>
       <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" exact Component={Home} />
            <Route path="/about" Component={About} />
            {/* <Route path="/services" Component={Services} />
            <Route path="/contact" Component={Contact} /> 
            <Route path="/career" Component={Career} />
            <Route path="/blogs" Component={blogs} /> */}
          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
