import React from "react";
import style from "./Projects.module.css";
import sign from "../../assets/buttonSign.png";
import Project from "../../assets/Projects.png";
import Slash from "../Shared/slash/Slash";
import sideimg from "../../assets/Group.png";
import Accordian from "./Accordian/Accordian";
import Counts from "./Counts/Counts";

const Projects = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.inner}>
          <div className={style.logo}>
            <img src={Project} alt="" />
          </div>
          <div className={style.heading}>
            <span>
              <Slash rang="#FEC90C" />
              08. Our Projects
            </span>
            <h2>
              Project Done. Browse our latest Projects and Results of Brands
              Mitra
            </h2>
          </div>
          <div style={{display:"flex",alignItems: "center"}}>
            <button
              className="btn btn-light"
            >
              More Case Studies <img src={sign} alt="" srcset="" />
            </button>
          </div>
        </div>
        <div className={style.inner2}>
          <div className={style.accordian}>
            <Accordian/>
          </div>
          <div className={style.sideimage}>
            <img src={sideimg} alt="" />
          </div>
          <button
              className={`btn btn-light ${style.btn2}`}

            >
              More Case Studies <img src={sign} alt="" srcset="" />
          </button>
        </div>
      <Counts/>
      </div>
    </>
  );
};

export default Projects;
