import React from "react";
import "./accordian.css"

const Accordian = () => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionExample" data-bs-theme="dark">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Online Media Management
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">Lorem Ipsum has been the industry's standard from dummy text ever since the unknown printer to galley of type and make.</div>
          </div>
        </div>
       
      </div>
      <div className="accordion accordion-flush" id="accordionExample" data-bs-theme="dark">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
             Experiential Marketing
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">Lorem Ipsum has been the industry's standard from dummy text ever since the unknown printer to galley of type and make.</div>
          </div>
        </div>
       
      </div>
      <div className="accordion accordion-flush" id="accordionExample" data-bs-theme="dark">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
             Experiential Marketing
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">Lorem Ipsum has been the industry's standard from dummy text ever since the unknown printer to galley of type and make.</div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Accordian;