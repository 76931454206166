import React, { useState, useRef } from "react";
import style from "../contact.module.css";
import emailjs from "@emailjs/browser";
const Contactform = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    Name: "",
    company: "",
    email_id: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.Name.trim() === "" ||
      formData.company.trim() === "" ||
      formData.email_id.trim() === "" ||
      formData.subject.trim() === "" ||
      formData.message.trim() === ""
    ) {
      alert("Empty block");
    } else {
      emailjs
        .sendForm("service_zv7lc0o", "template_ho7ra08", form.current, {
          publicKey: "hnIQJbGrjJr8BHfUK",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            setFormData({
              Name: "",
              company: "",
              email_id: "",
              subject: "",
              message: "",
            });
          },
          (error) => {
            console.log("FAILED...");
          }
        );
    }
  };

  return (
    <>
      <form className={style.form} ref={form} onSubmit={handleSubmit}>
        <div className={style.first}>
          <div className={style.block}>
            <label>Full Name *</label>
            <input
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div className={style.block}>
            <label>Email *</label>
            <input
              name="email_id"
              value={formData.email_id}
              onChange={handleChange}
              type="email"
              placeholder="example@yourmail.com"
            />
          </div>
        </div>
        <div className={style.second}>
          <div className={style.block}>
            <label>Company *</label>
            <input
              name="company"
              value={formData.company}
              onChange={handleChange}
              type="text"
              placeholder="Your Company Name here"
            />
          </div>
          <div className={style.block}>
            <label>Subject *</label>
            <input
              name="subject"
              type="text"
              placeholder="How can we Help"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={style.block}>
          <label>Message *</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>{" "}
        </div>
        <button
          type="submit"
          value="Send"
          className={`btn btn-dark ${style.btn2}`}
        >
          Send Message
        </button>
      </form>
    </>
  );
};

export default Contactform;

// service_zv7lc0o
// template_ko6zqsh
