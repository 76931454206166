import React from "react";
import style from "./footer.module.css";
import FooterCard from "./FooderCard/FooterCard";
import phone from "../../assets/phoneIcon.png";
import location from "../../assets/locationIcon.png";
import page from "../../assets/pages.png";
import follow from "../../assets/follow.png";
import behance from "../../assets/BehanceIcon.png";
import Facebook from "../../assets/Facebook.png";
import Linkdin from "../../assets/Linkdin.png";
import Instagram from "../../assets/Instagram.png";

const Footer = () => {
  return (
    <div className={style.container}>
      <FooterCard />
      <div className={style.innerContainer}>
        <div className={style.contact}>
          <div className={style.phone}>
            <span>
              {" "}
              <img src={phone} alt="" srcset="" /> <p>Get in touch with</p>
            </span>
            <span>+91 9131021945</span>
            <span>nextlevel@marketing.com</span>
          </div>
          <div className={style.location}>
            <span>
              {" "}
              <img src={location} alt="" /> <p>Location</p>
            </span>
            <span>10 Suna House 65 Rivington Street London EC2A 3QQ</span>
            <a href="">location</a>
          </div>
        </div>
        <div className={style.info}>
          <div className={style.routes}>
            <h4 className={style.title}>
              <img src={page} alt="" /> Pages
            </h4>
            <span>Home</span>
            <span>About</span>
            <span>Blog</span>
            <span>Projects</span>
            <span>Contact</span>
            <span>Services</span>
          </div>
          <div className={style.socialMedia}>
            <h4>
              <img src={follow} alt="" /> Follows
            </h4>
            <img src={behance} alt="" srcset="" />
            <img src={Instagram} alt="" srcset="" />
            <img src={Facebook} alt="" srcset="" />
            <img src={Linkdin}alt="" srcset="" />
          </div>
        </div>
        <span className={style.copyright}>Copyright ©thebrandmitra |</span>
      </div>
    </div>
  );
};

export default Footer;
