import React from "react";
import style from "../Service.module.css";
import sign from "../../../assets/buttonSign.png"

const Devcards = (props) => {
  return (
    <>
      <div className={style.develop__option}>
        <span className={style.spanicon}>
          <img src={props.imgs} alt="icon" />
        </span>
        <h5>{props.head}</h5>
        <p>{props.about}</p>
        <button className={style.buttonbtn}>Know More <img src={sign} alt="" srcset="" /></button>
      </div>
    </>
  );
};

export default Devcards;